.project {
  position: relative;
  top: 0;
  left: 0;
  background-image: url("../../assets/214-[Converted]\(1\).jpg");
  background-attachment: fixed;
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.project .container {
  margin: auto;
  max-width: var(--max-width);
  padding: 10% 2% 4rem;
}
.header_project {
  max-width: 100%;
  text-align: center;
  color: var(--Sec-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 50px 0 30px; */
}

.header_project h2 {
  font-size: 50px;
  letter-spacing: 15px;
  text-transform: capitalize;
  text-shadow: 0 0 10px var(--Tre-color);
}
.header_project p {
  max-width: 90%;
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.7;
}

.body_project {
  position: relative;
  /* width: 100%; */
  border: 2px solid #f5f5f53b;
  border-radius: 12px;
  margin-top: 50px;
  padding: 40px 30px;
}
.header_ser {
  color: var(--Sec-color);
  position: absolute;
  top: -30px;
  padding: 8px 20px 8px 0;
  border-radius: 30px;
  margin: 0;
  background-color: var(--Tre-color);
}

.header_ser h2 {
  position: relative;
  text-transform: capitalize;
  z-index: 5;
  /* margin-bottom: 20px; */
}
.header_ser h2::before {
  content: attr(att);
  position: absolute;
  top: -4px;
  left: -7px;
  color: var(--Tre-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Sec-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 0;
}
.body_project .items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 50px;
  color: var(--Sec-color);
}
.body_project .item {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 0 10px;
  border: 1px solid var(--Pr-color);
  border-radius: 12px;
  cursor: pointer;
  padding-bottom: 10px;
}
.body_project .item label {
  position: absolute;
  top: -10px;
  left: -10px;
  content: "";
  z-index: 10;
  background-color: var(--Pr-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body_project .item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
}

.body_project .item h2 {
  padding: 10px;
  text-transform: capitalize;
}
.body_project .item p {
  font-size: 12px;
  opacity: 0.7;
  padding: 10px;
}

@media (max-width: 767px) {
  .project {
    background-position: 50%;
    background-size: 150% 100%;
  }
  .project .container {
    padding: 25% 2% 4rem;
  }
  .body_project {
    padding: 40px 20px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* background-color: var(--Tre-color); */
}
html {
  scroll-behavior: smooth;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
section {
  position: relative;
  min-height: 100vh;
}
.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.ar {
  flex-direction: row-reverse;
}
.arLang {
  font-family: "Cairo";
  font-size: 20px;
}

.slide-in {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--Tre-color);
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--Tre-color);
  transform-origin: top;
  z-index: 99;
}

/* From Uiverse.io by Allyhere */
.btn-donate {
  --clr-font-main: hsla(0 0% 20% / 100);
  /* --btn-bg-1: hsla(115 85% 65% / 1); */
  --btn-bg-1: hsla(225 2% 44% /1);
  --btn-bg-2: hsla(180 3% 56% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 0.9em 1.4em;
  min-width: 120px;
  min-height: 44px;
  font-size: var(--size, 1rem);
  font-weight: 500;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(
    325deg,
    var(--btn-bg-2) 0%,
    var(--btn-bg-1) 55%,
    var(--btn-bg-2) 90%
  );
  border: none;
  outline: none;
  border-radius: var(--radii);
  color: var(--Sec-color);
  box-shadow: 0px 0px 20px rgba(109, 110, 113, 0.5),
    0px 5px 5px -1px rgba(109, 100, 113, 0.25),
    inset 4px 4px 8px rgba(152, 153, 158, 0.5),
    inset -4px -4px 8px rgba(93, 97, 107, 0.35);
}

.btn-donate:hover {
  background-position: right top;
}

.btn-donate:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}
.card .p-paginator  {
  background-color: transparent;
  margin-top: 20px;
  margin-bottom: 0;
}

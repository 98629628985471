.home {
  position: relative;
  top: 0;
  left: 0;
  background-image: url("../../assets/Untitled-1bus.jpg");
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.home img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home h2 {
  font-size: 60px;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--Sec-color);
  letter-spacing: 15px;
  transform: translate(-50%, 50%);
  text-shadow: 0 0 5px var(--Tre-color);
}

.home p {
  font-size: 15px;
  text-transform: capitalize;
  /* font-weight: 400; */
  white-space: nowrap;
  position: absolute;
  top: 70%;
  left: 50%;
  color: var(--Sec-color);
  letter-spacing: 5px;
  transform: translate(-50%, 100%);
  text-shadow: 0 0 5px var(--Tre-color);
}
@media (max-width: 767px) {
  .home {
    background-position: 50%;
    background-size: 150% 100%;
  }
  .home h2 {
    bottom: 30%;
    font-size: 50px;
    white-space: collapse;
    text-align: center;
    transform: translate(-50%, 100%);
  }
  .home p {
    font-size: 22px;
    bottom: 12%;
    white-space: collapse;
    text-align: center;
    width: 100%;
    transform: translate(-50%, 100%);
  }
  .home img {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }
}
.about_me {
  position: relative;
  height: 40vh;
  background-image: url("../../assets/214-[Converted]11.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about_me::before {
  background-color: var(--Tre-color);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.8;
}

.about_me h1 {
  position: relative;
  z-index: 2;
  font-size: 30px;
  margin-bottom: 20px;
  letter-spacing: 5px;
  text-transform: capitalize;
  color: var(--Sec-color);
}
.about_me p {
  color: var(--Sec-color);
  position: relative;
  z-index: 2;
  opacity: 0.7;
  max-width: 80%;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 767px) {
  .about_me {
    height: 50vh;
    background-position: center;
    background-size: cover;
  }
  .about_me h1 {
    text-align: center;
    font-size: 28px;
  }
  .about_me p {
    max-width: 90%;
    font-size: 12px;
    line-height: 2;
  }
}

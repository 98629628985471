.contact {
  position: relative;
  top: 0;
  left: 0;
  background-image: url("../../assets/214-[Converted]\(1\).jpg");
  background-attachment: fixed;
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.contact .container {
  margin: auto;
  max-width: var(--max-width);
  padding: 8% 2% 2.5rem;
}
.header_contact {
  max-width: 100%;
  text-align: center;
  color: var(--Sec-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 50px 0 30px; */
}

.header_contact h2 {
  font-size: 50px;
  letter-spacing: 15px;
  text-transform: capitalize;
  text-shadow: 0 0 10px var(--Tre-color);
}
.header_contact p {
  max-width: 90%;
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.7;
}

.body_contact {
  position: relative;
  /* width: 100%; */
  /* margin-top: 50px; */
  padding: 0px 5rem;
}

.header_con {
  color: var(--Sec-color);
  /* position: absolute; */
  top: -30px;
  padding: 8px 20px 8px 0;
  border-radius: 30px;
  margin: 0;
  /* background-color: var(--Tre-color); */
}

.header_con h2 {
  position: relative;
  text-transform: capitalize;
  z-index: 5;
  font-size: 18px;
  font-weight: 400;
  /* margin-bottom: 20px; */
}
.header_con h2.abs::before{
  left: unset;
  right: 0;
  top: -4px;
}
.header_con h2.abs::after{
  left: 0;
  right: unset;
}
.header_con h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 90%;
  height: 2px;
  background-color: var(--Pr-color);
  border-radius: 50%;
}
.header_con h2::before {
  content: attr(att);
  position: absolute;
  top: -8px;
  left: -4px;
  color: var(--Tre-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Sec-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 0;
}
.body_contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.body_contact .items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 50px;
  color: var(--Sec-color);
}
.body_contact .item {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 0 10px;
  border: 1px solid var(--Pr-color);
  border-radius: 12px;
  cursor: pointer;
  padding-bottom: 10px;
}
.body_contact .item label {
  position: absolute;
  top: -10px;
  left: -10px;
  content: "";
  z-index: 10;
  background-color: var(--Pr-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body_contact .item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
}

.body_contact .item h2 {
  padding: 10px;
  text-transform: capitalize;
}
.body_contact .item p {
  font-size: 12px;
  opacity: 0.7;
  padding: 10px;
}
.body_contact .social {
  margin-top: 15px;
}
.body_contact .social .icon {
  text-align: center;
  padding: 15px 20px 20px;
  position: relative;
}
.body_contact .social .icon::after {
  position: absolute;
  content: "";
  width: 60%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  background-color: var(--Pr-color);
  border-radius: 50%;
}
.body_contact .social .icon i {
  font-size: 25px;
}
.body_contact .social .icon p {
  opacity: 0.7;
}
.body_contact .left {
  flex: 0.9;
}
.body_contact .left .form_child {
  display: flex;
  gap: 10px 20px;
  justify-content: space-between;
  align-items: center;
}
.body_contact .left .form_child .child {
  width: 100%;
  position: relative;
  transition: 0.4s;
}
.body_contact .left .form_child .child.dinone::before {
  transform: translateY(0);
  opacity: 1;
}
.body_contact .left .form_child .child.abs::before{
  left: unset;
  right: 10px;
}
.body_contact .left .form_child .child::before {
  transform: translateY(-500px);
  opacity: 0;
  position: absolute;
  content: attr(label);
  color: var(--Sec-color);
  background-color: var(--Pr-color);
  border-radius: 10px;
  padding: 5px 12px;
  font-size: 10px;
  text-transform: capitalize;
  top: -20px;
  left: 12px;
  transition: transform 0.4s;
}
.body_contact .left .form_child:nth-child(2) {
  margin-top: 25px;
}
.body_contact .left .form_child input,
.body_contact .left .form_child textarea {
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  padding: 10px 20px;
  border-radius: 12px;
  color: var(--Sec-color);
  font-size: 15px;
  outline: none;
  box-sizing: none;
  width: 100%;
}

@media (max-width: 767px) {
  .contact {
    background-position: 50%;
    background-size: 150% 100%;
    min-height: 100vh;
  }
  .header_con {
    padding: 0 0 6rem 0;
  }
  .header_contact h2 {
    letter-spacing: 5px;
  }
  .header_con h2 {
    font-size: 18px;
  }
  .contact .container {
    padding: 25% 0 5rem;
  }
  .body_contact .left {
    width: 100%;
  }
  .body_contact .left .form_child {
    flex-direction: column;
    width: 100%;
  }
  .body_contact {
    padding: 20px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.btn {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
.btn button {
  background-color: var(--Pr-color);
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 0;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 2px;
  box-shadow: none;
  transition: all 0.4s;
}
.btn button:hover {
  border-radius: 10px;
  letter-spacing: 6px;
  box-shadow: 0 0 5px var(--Pr-color);
}


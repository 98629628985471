.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100;
  transition: background-color 0.4s;
}
.header.scrollClassName {
  background-color: var(--Tre-color);
}
.nav {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.5rem;
  margin-inline: none;
  background-color: transparent;
}
.nav_logo {
  font-weight: 600;
  color: var(--Sec-color);
  flex: 0.2;
}
.nav_logo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.nav_close,
.nav_toggle {
  color: var(--Sec-color);
  cursor: pointer;
  font-size: 30px;
  display: flex;
}

@media (max-width: 1150px) {
  .nav_menu {
    position: fixed;
    left: -100%;
    top: 0;
    background-color: var(--Pr-color);
    width: 100%;
    height: 100%;
    padding: 6rem 3.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 40px;
    transition: left 0.4s;
  }
}

.nav_list {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.nav_link {
  position: relative;
  color: var(--Sec-color);
  font-size: 20px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  transition: opacity 0.4s;
}
.nav_link i {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  font-size: 2rem;
  transform: rotate(-45deg);
  transition: opacity 0.4s, visibilityi 0.4s;
}
.nav_link span {
  position: relative;
  transition: margin 0.4s;
}
.nav_link span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
  width: 0;
  height: 2px;
  background-color: var(--Sec-color);
  transition: width 0.4s ease-out;
}
.nav_link:hover span,
.nav_link.active span {
  margin-left: 2rem;
}

.nav_link:hover i,
.nav_link.active i {
  opacity: 1;
  visibility: visible;
}

.nav_link:hover span::after,
.nav_link.active span::after {
  width: 100%;
}
.nav_list:has(.nav_link:hover) .nav_link:not(:hover),
.nav_list:has(.nav_link.active) .nav_link:not(.active) {
  opacity: 0.4;
}
.nav_close {
  position: absolute;
  top: 2rem;
  right: 1.5rem;
}

.show-menu {
  left: 0;
}

@media (min-width: 1150px) {
  .nav {
    padding: 1.5rem 5rem;
  }
  .nav_toggle,
  .nav_close {
    display: none;
  }
  .nav_link {
    font-size: 18px;
  }
  .nav_link i {
    font-size: 1.5rem;
  }
  .nav_list {
    flex-direction: row;
    column-gap: 3.5rem;
  }
  .nav_menu {
    display: flex;
    align-items: center;
    column-gap: 3.5rem;
  }
}

.nav_menu .nav_tra select {
  padding: 6px 12px;
  background-color: transparent;
  border: 2px solid var(--Pr-color);
  color: var(--Sec-color);
  font-size: 14px;
  border-radius: 12px;
}
.nav_menu .nav_tra select option {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  font-size: 16px;
  text-align: center;
  font-family: "Cairo";
  font-weight: 600;
  width: 60px;
}
.nav_menu .nav_tra select option:hover {
  background-color: var(--Pr-color);
}

.about {
  position: relative;
  top: 0;
  left: 0;
  background-image: url("../../assets/214-[Converted]\(1\).jpg");
  background-attachment: fixed;
  background-position: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}
.about .container {
  margin: auto;
  max-width: var(--max-width);
  padding: 8% 2% 4rem;
}
.header_about {
  max-width: 100%;
  text-align: center;
  color: var(--Sec-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 50px 0 30px; */
}

.header_about h2 {
  font-size: 50px;
  text-transform: capitalize;
  text-shadow: 0 0 10px var(--Tre-color);
}
.header_about p {
  max-width: 90%;
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
  opacity: 0.7;
}

.body_about {
  position: relative;
  /* width: 100%; */
  border: 2px solid var(--Sec-color);
  border-radius: 12px;
  margin-top: 80px;
  padding: 40px 30px;
}
.header_ser {
  color: var(--Sec-color);
  position: absolute;
  top: -30px;
  padding: 8px 20px 8px 0;
  border-radius: 30px;
  margin: 0;
  background-color: var(--Tre-color);
}

.header_ser h2 {
  position: relative;
  text-transform: capitalize;
  z-index: 5;
  /* margin-bottom: 20px; */
}
.header_ser h2::before {
  content: attr(att);
  position: absolute;
  top: -4px;
  left: -7px;
  color: var(--Tre-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Sec-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 0;
}
.body_about .items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 50px;
  color: var(--Sec-color);
}
.body_about .item {
  display: flex;
  gap: 0 10px;
  justify-content: space-between;
}
.body_about .item .right i {
  background-color: var(--Pr-color);
  color: var(--Tre-color);
  padding: 10px 10px;
  border-radius: 20px;
  font-size: 20px;
}

.body_about .item .left h2 {
  /* white-space: nowrap; */
  text-transform: capitalize;
}
.body_about .item .left p {
  font-size: 12px;
  opacity: 0.7;
}

@media (max-width: 767px) {
  .about {
    background-position: 50%;
    background-size: 150% 100%;
  }
  .about .container {
    padding: 25% 2% 4rem;
  }
  .body_about {
    padding: 40px 20px;
  }
}
